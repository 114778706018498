@import '../../styles/shared';

.image {
  border-radius: 50%;
  border: 6px solid $primary;
  z-index: 100;

  img {
    border-radius: 50%;
  }
}
