@import '../../styles/shared';

.card {
  cursor: pointer;
  margin-bottom: 60px;

  @include on-desktop {
    margin-bottom: 100px;
  }
}

.resources {
  margin-top: -20vh;
}
