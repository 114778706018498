@import "../../styles/shared";

.HorizontalLine {
  width: 80px;
  height: 6px;
  margin-top: 24px;
  margin-bottom: 24px;

  &.primary {
    background-color: $primary;
  }

  &.accent {
    background-color: $tertiary;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }
}
