@import '../../styles/shared';

.row {
  .icon {
    border-radius: 50%;
    background-color: $primary;
  }

  @include on-desktop {
    & > div {
      border-right: 1px solid $primary-light2;
      padding-left: 16px;
    }

    & > div:last-child {
      border-right: none;
    }

    & > div:first-child {
      padding-left: 0;
    }
  }
}
