@import '../../styles/shared';

.section {
  margin-bottom: 2rem;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;

    @include from-desktop-up {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 2rem;
      row-gap: 2rem;
      margin-bottom: 2rem;
    }

    @include on-large {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .hiring {
    margin-top: 1rem;
    background-color: $primary;
    color: $white;

    @include from-tablet-up {
      margin-top: unset;
      min-height: 456px;
    }

    .button {
      padding: 28px;
    }
  }
}
