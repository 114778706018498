@import '../../styles/shared';

.summaryCard {
  width: $full-width;
  min-height: 80vw;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  @include from-tablet-up {
    min-height: 456px;
  }

  .image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all 1s;
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(80vw - 144px);

    @include from-tablet-up {
      top: unset;
      height: 100%;
    }

    &:hover {
      transform: scale(1.2);
    }
  }

  .textBox {
    width: 100%;
    padding: 12px;
    background-color: rgba($dark, 1);
    z-index: 2;
    position: absolute;
    color: $white;
    text-align: center;
    min-height: 144px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include from-tablet-up {
      text-align: left;
      min-height: unset;
      background-color: rgba($dark, 0.5);
    }

    .title {
      font-weight: 700;
    }

    .subtitle {
      font-weight: 400;
    }
  }
}
