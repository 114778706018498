@import "../../styles/shared";

.carouselControls {
  width: max-content;
  cursor: pointer;

  div {
    background-color: $white70;

    &:hover {
      background-color: $dark;

      svg {
        color: $white;
      }
    }

    &.greyed {
      background-color: $white50;
      cursor: default;

      svg {
        color: $white;
      }
    }
  }

  &.large {
    div {
      width: $button-large-size;
      height: $button-large-size;
    }
  }

  &.small {
    div {
      width: $button-small-size;
      height: $button-small-size;
    }
  }
}
