$black: #000;
$dark: #091329;
$paper: #f2f9f9;
$paper-home: #f0f6f7;
$white: #fff;
$white10: #ffffff1a; // white but not as we known it
$white50: #ffffff80; // rgba(255, 255, 255, 0.5)
$white70: #ffffffb3; // rgba(255, 255, 255, 0.7)
$primary: #008190;
$primary-light: #99ccd2;
$primary-lighter: #CCE6E9;
$primary-hover: #006E7B;
$secondary: #091329;
$secondary-light: #091329b3; // rgba(9, 19, 41, 0.7)
$primary-light2: #00819080; // rgba(0, 129, 144, 0.5)
$tertiary: #f39237;
$dark-blue: #091329;
$back-button-hover: #3a4254;
$green-light: #f0f6f7;
$icon-background: #e6f3f4;
$font-weight-normal: normal;
$font-weight-bold: 600;
$full-width: 100%;
$max-narrow-content-width: 564px;
$max-content-width: 1156px;
$max-hero-width: 1440px;
$header-height-mobile: 70px;
$header-height: 109px;

// buttons
$button-large-size: 90px;
$button-small-size: 48px;
$pagination-button-size: 44px;

// max-content-width + 2 x 16px margins
$media-query-breakpoint-large-content: 1188px;
$media-query-breakpoint-xs: 0;
$media-query-breakpoint-sm: 680px;
$media-query-breakpoint-md: 1000px;
$media-query-breakpoint-lg: 1280px;
$media-query-breakpoint-xl: 1920px;
$media-query-landscape-breakpoint-sm: 414px;
$media-query-landscape-breakpoint-md: 800px;
