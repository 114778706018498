@import '../../styles/shared';

.section {
  display: flex;
  gap: 32px;
  padding-block: 2rem;

  .bar {
    width: 16px;
    background-color: $primary;
  }

  .content {
    flex-grow: 1;

    .title {
      margin-bottom: 1em;
    }
  }
  
}
