@import '../../styles/shared';

.projectSummaryCard {
  max-width: 328px;
  width: 100%;

  @include from-tablet-up {
    max-width: 562px;
  }

  .image {
    span {
      overflow: hidden;
      transition: all 1s;
    }
  }

  &:hover {
    .image {
      span {
        transform: scale(1.2);
      }
    }
  }
}
