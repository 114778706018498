@mixin from-desktop-up {
  @media (min-width: $media-query-breakpoint-md) {
    @content;
  }
}

@mixin from-tablet-up {
  @media (min-width: $media-query-breakpoint-sm) {
    @content;
  }
}

@mixin on-large {
  @media (min-width: $media-query-breakpoint-lg) {
    @content;
  }
}
@mixin on-tablet {
  @media (min-width: $media-query-breakpoint-sm) and (max-width: $media-query-breakpoint-md) {
    @content;
  }
}

@mixin on-mobile {
  @media (max-width: $media-query-breakpoint-sm) {
    @content;
  }
}

@mixin up-to-tablet {
  @media (max-width: $media-query-breakpoint-md) {
    @content;
  }
}

@mixin on-desktop {
  @media (min-width: $media-query-breakpoint-sm ) {
    @content;
  }
}

@mixin on-desktop-with-height {
  @media (min-width: $media-query-breakpoint-sm ) and (min-height: $media-query-breakpoint-sm ) {
    @content;
  }
}

@mixin on-tablet-height {
  @media (min-height: $media-query-breakpoint-sm) and (max-height: $media-query-breakpoint-md) {
    @content;
  }
}

@mixin on-desktop-height {
  @media (min-height: $media-query-breakpoint-md ) {
    @content;
  }
}

@mixin on-larger-content {
  @media (min-width: $media-query-breakpoint-large-content) {
    @content;
  }
}

@mixin for-desktop-to-larger-content {
  @media (min-width: $media-query-breakpoint-md) and (max-width: $media-query-breakpoint-large-content) {
    @content;
  }
}

@mixin for-large-desktop-design {
  @media (min-width: $media-query-breakpoint-lg) {
    @content;
  }
}
