@import '../../styles/shared';

.projects {
  margin-top: -20vh;

  .card {
    margin-bottom: 60px;

    @include on-desktop {
      margin-bottom: 100px;
    }
  }
}
