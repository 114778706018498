@import '../../styles/shared';

.socialIcons {
  .icon {
    border-radius: 50%;

    &.dark {
      border: 1px solid $white;

      &:hover {
        background-color: $tertiary;
        border: 1px solid $tertiary;
      }
    }

    &.light {
      border: 1px solid $primary;

      &:hover {
        color: $white;
        background-color: $primary;

        svg {
          color: $white;
        }
      }
    }

    &.small {
      width: 32px;
      height: 32px;
    }

    &.large {
      height: 40px;
      width: 40px;
    }

    &.clickable {
      cursor: pointer;
    }
  }
}
