@import '../../styles/shared';

.section {
  z-index: 1;

  .sectionTop {
    @include on-mobile {
      width: 100vw;
    }
  }

  &.dark::after {
      opacity: 0.2;
      color: blue;
  }

  &::after {
    content: '';
    inset: 0;
    position: absolute;
    pointer-events: none;
    background-repeat: no-repeat;
    z-index: 0;
    background-image: url('../../../public/constellation-08.svg');
    background-position: right;
    background-position-x: 55vw;
    background-position-y: calc(100vh - 95vh);
  }

  @include on-desktop {
    &::after {
      background-image: url('../../../public/constellation-11.svg'),
        url('../../../public/constellation-07.svg'),
        url('../../../public/constellation-09.svg');
      background-position: left, center, right;
      background-position-x: 40vw, 65vw, 75vw;
      background-position-y: 45vh, 15vh, 35vh;
    }
  }

  .title {
    word-wrap: break-word;

    strong {
      color: $primary;
    }
  }

  .subtitle {
    margin-bottom: 40px;
  }

  .items {
    .itemTitle {
      color: $tertiary;
    }

    @include on-desktop {
      width: fit-content;

      .itemText {
        margin-top: 8px;
      }

      & > div {
        border-right: 1px solid $primary-light2;
        padding-left: 16px;
      }

      & > div:last-child {
        border-right: none;
      }

      & > div:first-child {
        padding-left: 0;
      }
    }

    @include on-mobile {
      & > div {
        border-bottom: 1px solid $primary-light2;
        padding: 16px 0;
      }

      & > div:last-child {
        border-bottom: none;
      }
    }
  }
}

.heroTextBox {
  transform: translateY(-50%);
}
