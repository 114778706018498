@import '../../styles/shared';

.section {
  position: relative;

  &.full {
    padding: 100px 16px;

    @include from-tablet-up {
      padding: 120px 0;
    }
  }

  &.onlyTitle {
    padding: 100px 16px 60px;

    @include from-tablet-up {
      padding: 120px 0 60px;
    }
  }

  .container {
    &.base {
      margin: 0 auto;
      max-width: $max-narrow-content-width;
    }

    .title {
      word-wrap: break-word;
    }

    .center {
      @extend %text-center;
    }
  }

  .link {
    display: inline-block;
    border: 2px solid $primary;
    text-transform: uppercase;
    padding: 24px;
  }

  &.isLeft {
    .container .title::after {
      margin-left: 0;
    }
  }

  &.isRight {
    .container .title {
      text-align: right;

      &::after {
        margin-right: 0;
      }
    }
  }
}
