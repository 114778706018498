@import '../../styles/shared';

.projectSummaryCardHero {
  height: auto;
  width: 100%;
  padding: 40px 0;
  color: $white;
  background-color: $dark;

  @include from-tablet-up {
    padding: 100px 0;
  }
  
  .image {
    span {
      overflow: hidden;
      transition: all 1s;
    }
  }

  &:hover {
    .image {
      span {
        transform: scale(1.2);
      }
    }
  }
}
