@import "../../styles/shared";

.filters {
  width: 100%;
  height: 40px;
  border-bottom: 2px solid $primary-light2;
  z-index: 100;

  .filters_item {
    width: 192px;
    height: inherit;
    cursor: pointer;
    text-transform: uppercase;
    z-index: 100;

    &.selected {
      border-bottom: 2px solid $primary;
      cursor: default;
    }
  }
}

.filters_mobile {
  width: 192px;
}
