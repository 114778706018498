@import "../../styles/shared";

.tags {
  background-color: $primary;
  height: 28px;
  width: fit-content;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 2.1px;
}
