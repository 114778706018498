@import "../../styles/shared";

.row {
  .card {
    border-left: 4px solid $tertiary;

    @include from-tablet-up {
      border-left: unset;
      border-top: 4px solid $tertiary;
    }
  }

  @include from-tablet-up {
    > div {
      margin-right: 16px;
    }

    & > div:last-child {
      margin-right: 0;
    }
  }
}
