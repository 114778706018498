@import '../../styles/shared';

.card {
  background-color: $white;
  color: $dark;
}

.darkCard {
  background-color: $white10;
  color: $white;
}

.icon {
  border-radius: 50%;
  background-color: $icon-background;
  padding: 12px;
  aspect-ratio: 1/1;

  &.darkCard {
    background-color: $primary;
  }

  &.noCard {
    background-color: transparent;
  }
}
