@import '../../styles/shared';

.desktopBox {
  @include on-tablet-height {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  @include on-desktop-height {
    margin-top: -80px;
    margin-bottom: -80px;
  }
}

.line {
  background-color: $primary-light;
}

.ball {
  z-index: 1;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: $primary;
  border: 8px solid $primary-lighter;
  transition: 0.1s all ease-in ;
}
