// Note: Do not put anything in here that you
// don't want to be repeated for each component

@import './variables';
@import './mixins';

%text-center {
  text-align: center;
}

%text-white {
  color: $white;
}

// Note: Do not put anything in here that you
// don't want to be repeated for each component
