@import '../../styles/shared';

.footer {
  padding-bottom: 16px;

  .container {
    max-width: $max-content-width;
    margin: auto;

    .link {
      h2::after {
        content: ' ';
        display: inline-block;
        background-image: url('../../../public/arrow-primary.svg');
        background-size: 32px 32px;
        width: 32px;
        height: 32px;
        margin-left: 16px;

        @include from-desktop-up {
          margin-left: 32px;
          background-size: 60px 60px;
          width: 60px;
          height: 60px;
          transform: translateY(14px);
        }
      }

      &:hover {
        h2 {
          color: $tertiary;

          &::after {
            background-image: url('../../../public/arrow-tertiary.svg');
          }
        }
      }
    }

    .menu {
      max-width: $max-content-width;

      .link {
        color: $white;

        @include on-mobile {
          justify-content: left;
        }
      }
    }
  }
}
