@import "../../styles/shared";

.backToTop {
  background-color: transparent;
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
  transition: all .5s ease-in-out;

  .top,
  .bottom,
  .left,
  .right {
    position: absolute;

    div {
      position: absolute;
      background-color: $primary;
    }
  }

  .top {
    top: 0;
    right: 0;

    div {
      right: 0;
    }
  }

  .left {
    top: 0;
    left: 0;
  }

  .bottom {
    bottom: 0;
    left: 0;
  }

  .right {
    bottom: 0;
    right: 0;

    div {
      bottom: 0;
    }
  }

  .top,
  .bottom {
    height: 4px;
    width: 100%;

    div {
      height: 100%;
    }
  }

  .left,
  .right {
    width: 4px;
    height: 100%;

    div {
      width: 100%;
    }
  }

  &.large {
    width: $button-large-size;
    height: $button-large-size;
  }

  &.small {
    width: $button-small-size;
    height: $button-small-size;
  }

  .inner {
    &.primary {
      background-color: $primary;

      &:hover {
        background-color: $back-button-hover;
      }
    }
    
    &.secondary {
      background-color: $dark;

      &:hover {
        background-color: $back-button-hover;
      }
    }

    &.inner_large {
      width: 78px;
      height: 78px;
    }

    &.inner_small {
      width: 38px;
      height: 38px;
    }
  }
}
