@import "../../styles/shared";

.section {
  padding: 0;
  
  &.limitcontent {
    max-width: $max-content-width;
  }

  &.content {
    max-width: 100vw;
  }
}
