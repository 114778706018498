@import '../../styles/shared';

.card {
  background-color: $white;

  .content {
    display: flex;
    flex-direction: column;

    @include from-desktop-up {
      flex-direction: unset;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
    }
  }
}

