@import "../../styles/shared";

.SRWMenuDrawer {
  .cross {
    cursor: pointer;
  }

  .paper {
    &.dark {
      background-color: $dark;
      color: $white;
    }

    &.light {
      background-color: $primary;
      color: $white;
    }
  }
}
