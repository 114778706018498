@import '../../styles/shared';

$animation-time: 600ms;

.section {
  width: 100%;
  max-width: 100vw;
  background-color: $dark;
  color: $white;

  @include from-desktop-up {
    margin-top: unset;
    padding: 0;
  }
}

.container {
  // avoid the exhaust fumes creating x-scroll
  overflow: hidden;
  background-color: $dark;
}

.slide {
  margin-top: 40px;
  margin-bottom: 100px;

  @include from-desktop-up {
    height: 100vh;
    margin-top: 0;
    margin-bottom: 0;

    // offset by the amount of the steps, since the steps need to be absolute
    padding-right: 145px;
  }
}

.entering {
  opacity: 0;
  top: 10px;
  transition: opacity $animation-time, top $animation-time;
}

.entered {
  opacity: 1;
  top: 0;
  transition: opacity $animation-time, top $animation-time;
}

.exited {
  opacity: 0;
  top: -50px;
  transition: opacity $animation-time, top $animation-time;
}

.image {
  img {
    max-width: 80vw;
    max-height: 40vh;
  }

  @include from-desktop-up {
    height: 100%;
    padding-top: $header-height;
    display: grid;
    place-items: center;

    span {
      z-index: 1;
    }

    img {
      max-width: unset;
      max-height: unset;
    }

    &::after {
      content: '';
      inset: 0;
      position: absolute;
      pointer-events: none;
      background-image: url('../../../public/constellation-01.svg'),
        url('../../../public/constellation-11.svg');
      background-position: left, right;
      background-repeat: no-repeat;
      background-position-x: 5vw, 33vw;
      background-position-y: calc(100vh - 70vh), calc(100vh - 40vh);
      opacity: 0.2;
    }
  }
}

.slideWithLinks .image {
  img {
    max-width: 40vw !important;
  }
}

.link {
  color: $primary;
}

.title {
  padding-left: 8px;

  strong {
    color: $tertiary;
  }
  @include from-desktop-up {
    padding-left: 0;

    &::after {
      content: '';
      inset: 0;
      position: absolute;
      pointer-events: none;
      background-image: url('../../../public/constellation-08.svg'),
        url('../../../public/constellation-02.svg');
      background-position: bottom right, top right;
      background-repeat: no-repeat;
      background-position-x: 85vw, 85vw;
      background-position-y: calc(100vh - 85vh), calc(100vh - 22vh);
      opacity: 0.2;
    }
  }
}

.step {
  position: absolute;
  right: 0;
  height: 100vh;
  z-index: 1000;

  @include on-larger-content {
    padding-right: 16px;
    padding-left: 0;
  }

  display: grid;
  place-items: center;

  li {
    list-style-type: none;
    display: flex;
    position: relative;
    margin: 16px 0;
  }

  .stepLabel {
    cursor: pointer;
    color: $tertiary;
    text-align: right;
    opacity: 0;
    transition: opacity $animation-time / 3;
    flex-grow: 1;
    margin-right: 8px;

    &.active {
      opacity: 1;
      transition: opacity $animation-time / 3;
    }
  }

  .stepContainer {
    position: relative;
  }

  .stepLine {
    position: absolute;
    height: calc(100% - 45px);
    top: 20px;
    right: 29px;
    width: 1px;
    border-right: solid 2px $primary;
  }

  .stepDotInner {
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 10px;
  }

  li:hover {
    .stepLabel {
      opacity: 1;
      transition: opacity $animation-time / 3;
    }

    .stepDotInner {
      background-color: $primary;
    }
  }

  .stepDot {
    position: relative;
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    background-color: $dark;
    border: 2px solid $primary;
    border-radius: 20px;

    &.stepDotActive {
      .stepDotInner {
        background-color: $primary;
      }
    }
  }
}

.imageContainer {
  position: relative;
}

.slideWithLinks {
  .title {
    display: none;

    @include from-desktop-up {
      display: block;
    }
  }

  .imageContainer {
    animation: bounce 0.5s infinite;
    animation-delay: 1000ms;
  }
}

.linksPageShown {
  opacity: 1 !important;
}

$links-offset: 40px;

.linksPage {
  position: relative;
  color: $dark;
  background-color: $green-light;
  max-width: unset;
  padding-top: $links-offset;
  opacity: 0;
  transition: opacity ease-in 0.7s;

  @include on-desktop {
    padding-top: $links-offset + 50px;
    padding-bottom: 80px;

    &::after {
      content: '';
      inset: 0;
      position: absolute;
      pointer-events: none;
      color: $primary;
      background-image: url('../../../public/constellation-01.svg'),
        url('../../../public/constellation-02.svg'),
        url('../../../public/constellation-10.svg');
      background-position: left, center, right;
      background-repeat: no-repeat;
      background-position-x: 10vw, 50vw, 80vw;
      background-position-y: 10vh, 65vh, 20vh;
      z-index: 0;
    }
  }

  .titleLinks {
    place-items: unset;
    text-align: center;
  }

  .pages {
    max-width: 100%;
    margin: auto;
    margin-bottom: 140px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include on-tablet {
      flex-flow: wrap row;
    }

    @include from-desktop-up {
      max-width: $max-content-width;
      flex-direction: row;
    }

    .card {
      background-color: $white;
      padding: 24px;
      margin: 8px;
      transition: box-shadow 0.05s linear;
      z-index: 1;

      @include on-tablet {
        max-width: 252px;
      }

      @include from-desktop-up {
        max-width: 24%;
      }

      .icon {
        border-radius: 50%;
        background: $icon-background;
        height: 60px;
        width: 60px;
        font-size: 20px;
      }

      &:hover {
        box-shadow: 7px 7px 0 0 $primary;
      }
    }
  }
}

// final slide rocket exhaust

.exhaustFlame {
  position: absolute;
  top: 70%;
  width: 40px;
  left: calc(50% - 20px);
  background: linear-gradient(to top, transparent 10%, $green-light 60%);
  height: 250px;
  animation: exhaust 0.2s infinite;
  opacity: 0;
  transition: opacity ease-in 0.7s;

  @include on-desktop {
    width: 60px;
    left: calc(50% - 30px);
    height: 350px;
  }

  &.exhaustFlameShown {
    opacity: 1;
    z-index: 100;
  }
}

.exhaustFumes {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: -50px;

  @include on-desktop {
    top: -120px;
  }
}

.whiteBar {
  width: calc(100vw + 24px);
  background-color: $green-light;
  height: 50px;
  left: -24px;
  top: 100px;

  @include on-desktop {
    top: 170px;  
  }
}

// cloud groups

.staticClouds,
.animatedClouds,
.movingLeftClouds,
.staticRightClouds {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: -50px;
}

.animatedClouds {
  left: 40vw;
}

.movingLeftClouds {
  left: 40px;

  // stays inline with the rocket, so a more dramatic cloud is always under the thrusters
  @media (width >= 1300px) {
    left: 100px;
    top: 30px;
  }

  @media (width >= 1500px) {
    left: 200px;
    top: 30px;
  }
}

.staticRightClouds {
  width: 100vw;
}

// individual cloud blobs

.staticCloud,
.animatedCloud,
.movingLeftCloud,
.staticRightCloud {
  background-color: $green-light;
  border-radius: 100%;
}

.staticCloud {
  @include on-mobile {
    // nth
    &:nth-child(5n + 1) {
      width: 75px;
      height: 75px;
      margin-top: 0;
      margin-left: -10px;
      animation: fumes2 2.5s infinite;
    }

    &:nth-child(5n + 2) {
      width: 75px;
      height: 75px;
      margin-top: 20px;
      margin-left: -10px;
      animation: fumes2 3s infinite;
    }

    &:nth-child(5n + 3) {
      width: 45px;
      height: 45px;
      margin-top: 25px;
      margin-left: -10px;
      animation: fumes2 3.5s infinite;
    }

    &:nth-child(5n + 4) {
      width: 45px;
      height: 45px;
      margin-top: 30px;
      margin-left: -10px;
    }

    &:nth-child(5n + 5) {
      width: 45px;
      height: 45px;
      margin-top: 35px;
      margin-left: -10px;
    }

    &:nth-child(1) {
      margin-left: -40px;
    }
  }

  @include on-desktop {
    // nth
    &:nth-child(5n + 1) {
      width: 150px;
      height: 150px;
      margin-top: 70px;
      margin-left: -20px;
    }

    &:nth-child(5n + 2) {
      width: 125px;
      height: 125px;
      margin-top: 80px;
      margin-left: -10px;
    }

    &:nth-child(5n + 3) {
      width: 100px;
      height: 100px;
      margin-top: 90px;
      margin-left: -15px;
      animation: fumes2 2.5s infinite;
    }

    &:nth-child(5n + 4) {
      width: 75px;
      height: 75px;
      margin-top: 100px;
      margin-left: -20px;
    }

    &:nth-child(5n + 5) {
      width: 80px;
      height: 80px;
      margin-top: 80px;
      margin-left: -15px;
      animation: fumes2 2.5s infinite;
    }

    // specifically 1st, 2nd, 3rd - handles left most 3 clouds forming a hill against the left side of the screen
    &:nth-child(1) {
      width: 200px;
      height: 200px;
      margin-top: -60px;
      margin-left: -40px;
    }

    &:nth-child(2) {
      width: 175px;
      height: 175px;
      margin-top: 20px;
      margin-left: -50px;
      animation: fumes2 2s infinite;
    }

    &:nth-child(3) {
      animation: fumes2 2.5s infinite;
    }
  }

  @media (min-width: $media-query-breakpoint-sm) and (max-width: $media-query-breakpoint-lg) {
    &:nth-child(1) {
      margin-top: -30px;
    }
  }
}

.animatedCloud {
  width: 160px;
  height: 160px;
  animation: fumes 5s infinite;

  // every other is a different size
  &:nth-child(2n + 1) {
    width: 160px;
    height: 160px;
    margin-top: 80px;
    margin-left: 120px;
  }

  &:nth-child(2n + 2) {
    width: 160px;
    height: 160px;
    margin-top: 90px;
    margin-left: 150px;
  }

  // differing speeds of animation for all
  &:nth-child(5n + 1) {
    animation: fumes 3.5s infinite;
  }

  &:nth-child(5n + 2) {
    animation: fumes 4s infinite;
  }

  &:nth-child(5n + 3) {
    animation: fumes 3.5s infinite;
  }

  &:nth-child(5n + 4) {
    animation: fumes 5s infinite;
  }

  &:nth-child(5n + 5) {
    animation: fumes 3.5s infinite;
  }
}

// stays inline with the rocket, so a more dramatic cloud is always under the thrusters
.movingLeftCloud {
  width: 160px;
  height: 160px;

  &:nth-child(1) {
    margin-top: -30px;
    animation: fumes 1.2s infinite;
  }

  &:nth-child(2) {
    margin-left: -30px;
    animation: fumes 1.5s infinite;
  }

  &:nth-child(3) {
    margin-left: -30px;
    animation: fumes 1.8s infinite;
  }
}

// handles right most 3 clouds forming a hill against the right side of the screen
.staticRightCloud {
  position: absolute;
  right: 0;

  &:nth-child(1) {
    width: 175px;
    height: 175px;
    margin-top: 20px;
    margin-right: 90px;
    animation: fumes2 2s infinite;
  }

  &:nth-child(2) {
    width: 200px;
    height: 200px;
    margin-top: -80px;
    margin-right: -50px;
  }
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -10px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes exhaust {
  0% {
    background: linear-gradient(to top, transparent 10%, $green-light 60%);
  }

  50% {
    background: linear-gradient(to top, transparent 8%, $green-light 60%);
  }

  75% {
    background: linear-gradient(to top, transparent 12%, $green-light 60%);
  }
}

@keyframes fumes {
  50% {
    transform: scale(1.5);
    background-color: transparent;
  }

  51% {
    transform: scale(0.8);
  }

  100% {
    background-color: $green-light;
    transform: scale(1);
  }
}

@keyframes fumes2 {
  50% {
    transform: scale(1.1);
  }
}
