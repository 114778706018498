.contain {
  img {
    object-fit: contain;
  }
} 

.cover {
  img {
    object-fit: cover;
  }
} 

.grow {
  img {
    overflow: hidden;
    transition: all 1s;
    
    &:hover {
      transform: scale(1.2);
    }
  }
}
