.icon {
  &.clickable {
    cursor: pointer;
  }

  &.duotone {
    --fa-primary-opacity: 1;
    --fa-primary-color: #008190;
    --fa-secondary-opacity: 1;
    --fa-secondary-color: #99CCD2;
  }
}
