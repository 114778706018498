@import "../../styles/shared";

.appBar {
  height: $header-height-mobile;
  transition: top ease-in-out .5s;

  @include on-desktop-with-height {
    height: $header-height;
  }

  &.hideHeader {
    top: -$header-height-mobile;
    @include on-desktop-with-height {
      top: -$header-height;
    }
  }

  .logo {
    cursor: pointer;
  }
}
